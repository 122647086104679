import React from 'react';
import Reveal from 'react-reveal/Reveal';
import PropTypes from 'prop-types';

const ScrollAnimation = ({ children, effect }) => {
  return <Reveal effect={effect}>{children}</Reveal>;
};

ScrollAnimation.propTypes = {
  effect: PropTypes.string,
};

ScrollAnimation.defaultProps = {
  effect: 'fadeInUp',
};

export default ScrollAnimation;
